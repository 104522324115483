<template>
  <div class="buylist-style">
    <titles :title="$t('text.t355')" />
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
      >
        <div class="item" v-for="item in list" :key="item.id">
          <div class="row2 between-center">
            <img :src="item.image" class="i2" />
            <div class="t3">{{ item.name }}#{{ item.tokenid }}</div>
            <div class="b1 end-center" @click="chooseBack(item)">
              <div>{{$t('text.t351')}}</div>
              <van-icon name="arrow" color="#909090" size="20" />
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import titles from "@/components/titles/index.vue";
export default {
  components: {
    titles,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
    };
  },
  computed: {
    
  },
  mounted() {},
  methods: {
    chooseBack(item){
      this.$bus.$emit('chooseNfts',item)
      this.$router.back()
    },
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
      };
      this.$http.get("/release/nftList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.buylist-style {
  .list {
    .item {
      padding: 20px;
      border-bottom: 1px solid #e1e1e1;
      .row2 {
        .i2 {
          width: 41px;
          height: auto;
          margin-right: 10px;
        }
        .t3 {
          flex: auto;
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .b1 {
          margin-left: 10px;
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
    }
  }
}
</style>